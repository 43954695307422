import React, { Component } from 'react'
import { Link, navigate } from 'gatsby'
import { MDBContainer, MDBRow, MDBCol, toast, ToastContainer } from 'mdbreact'
import { Zoom } from 'react-toastify'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'

const validationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(2, 'Name must be 2 characters at minimum')
    .required('Name is required'),
  surname: Yup.string()
    .min(2, 'Name must be 2 characters at minimum')
    .required('Name is required'),
  email: Yup.string()
    .email('Invalid email address format')
    .required('Email is required'),
  street: Yup.string()
    .min(5, 'Street must be 5 characters at minimum')
    .required('Street is required'),
  city: Yup.string()
    .min(2, 'City must be 2 characters at minimum')
    .required('City is required'),
  state: Yup.string()
    .min(2, 'State must be 2 characters at minimum')
    .required('State is required'),
  zip: Yup.string()
    .min(5, 'Zip/postcode must be 5 characters at minimum')
    .required('Zip/postcode is required'),
  country: Yup.string()
    .min(2, 'Country must be 2 characters at minimum')
    .required('Country is required'),
  mainphone: Yup.string()
    .min(10, 'Main phone number must be 10 characters at minimum')
    .required('Main phone number is required'),
  preferredlocation: Yup.string().required('Preferred location is required'), 
  yearsexpertise: Yup.string().required('Years of expertise is required'), 
  relocate: Yup.string().required('Willing to relocate is required'), 
  degree1: Yup.string().required('Degree and major #1 is required'),
  comments: Yup.string()
    .min(10, 'Explanation must be 10 characters at minimum')
    .required('How you heard about us is required'),
  // expertise: Yup.boolean().when('administrative', { is: false, then: Yup.boolean().required('Please select your areas of expertise')}), 
  })

const url = 'https://script.google.com/macros/s/AKfycbyR6EFATWT25q09Vvv8cJmNmvaOMDa0zgJCQDzwikrhtpmWAtjxy3Fl8wnE7qiR0tTA/exec'

const messageConfirm = () => {
  return toast.info("Thank you. Your resume has been submitted.", { closeButton: false, position: toast.POSITION.TOP_CENTER, transition: Zoom })
}


class formResume extends Component {
  constructor(props, ...args) {
    super(props, ...args)
    this.state = {
      callback: 'not fired',
      value: null,
      pageUrl: null, 
      load: false,
      expired: 'false',
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ 
        load: true, 
        pageUrl: location.href 
      })
    })
  }

  asyncScriptOnLoad = () => {
    this.setState({ callback: 'called!' })
  }

  render() {
    const { value, callback, load, expired } = this.state || {}
    const optionsRelocate = [
      { text: "Yes", value: "Yes" },
      { text: "No", value: "No" }
    ]

    return (
      <MDBContainer>
        <MDBRow className="justify-content-center">
          <MDBCol lg="9" md="12" className="md-0 mb-5">
            <Formik
              initialValues={{
                firstname: '',
                middlename: '',
                surname: '',
                checkboxOptions: [], 
                street: '',
                city: '',
                state: '',
                zip: '',
                country: '',
                email: '',
                mainphone: '',
                homephone: '',
                mobilephone: '',
                workphone: '',
                linkedin: '',
                preferredlocation: '',
                yearsexpertise: '',
                relocate: '',
                degree1: '',
                degree2: '',
                accounting: false,
                administrative: false,
                bidsproposals: '',
                contracts: '',
                departmentvp: '',
                engineeringmechanical: '',
                engineeringelectrical: '',
                engineeringindustrial: '',
                engineeringsoftware: '',
                engineeringtest: '',
                executivevp: '',
                facilities: '',
                finance: '',
                humanresources: '',
                informationtechnology: '',
                integrationservices: '',
                legal: '',
                manufacturing: '',
                marketing: '',
                partnermanagement: '',
                projectmanagement: '',
                purchasing: '',
                research: '',
                sales: '',
                software: '',
                comments: '',
                resume: '',
                subscribe: false,
              }}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                /* alert('Form is validated! Submitting the form...') */
                let formData = {
                  Firstname: values.firstname,
                  Middlename: values.middlename,
                  Surname: values.surname,
                  Street: values.street,
                  City: values.city,
                  State: values.state,
                  Zip: values.zip,
                  Country: values.country,
                  Email: values.email,
                  MainPhone: values.mainphone,
                  HomePhone: values.homephone,
                  MobilePhone: values.mobilephone,
                  WorkPhone: values.workphone,
                  LinkedIn: values.linkedin,
                  PreferredLocation: values.preferredlocation,
                  YearsExpertise: values.yearsexpertise,
                  Relocate: values.relocate,
                  Degree1: values.degree1,
                  Degree2: values.degree2,
                  Accounting: values.accounting,
                  Administrative: values.administrative,
                  BidsProposals: values.bidsproposals,
                  Contracts: values.contracts,
                  DepartmentVP: values.departmentvp,
                  EngineeringMechanical: values.engineeringmechanical,
                  EngineeringElectrical: values.engineeringelectrical,
                  EngineeringIndustrial: values.engineeringindustrial,
                  EngineeringSoftware: values.engineeringsoftware,
                  EngineeringTest: values.engineeringtest,
                  ExecutiveVP: values.executivevp,
                  Facilities: values.facilities,
                  Finance: values.finance,
                  HumanResources: values.humanresources,
                  InformationTechnology: values.informationtechnology,
                  IntegrationServices: values.integrationservices,
                  Legal: values.legal,
                  Manufacturing: values.manufacturing,
                  Marketing: values.marketing,
                  PartnerManagement: values.partnermanagement,
                  ProjectManagement: values.projectmanagement,
                  Purchasing: values.purchasing,
                  Research: values.research,
                  Sales: values.sales,
                  Software: values.software,
                  Comments: values.comments,
                  Resume: values.resume,
                  Subscribe: values.subscribe,
                }

                let fileData = new FormData()
                fileData.append('file', this.state.selectedFile)

                // upload resume
                const file = this.state.selectedFile
                //console.log(file)
                const fr = new FileReader()
                fr.readAsArrayBuffer(file)
                fr.onload = (f) => {
                  const qs = new URLSearchParams({
                    timestamp: '',
                    firstname: values.firstname,
                    middlename: values.middlename,
                    surname: values.surname,
                    street: values.street,
                    city: values.city,
                    state: values.state,
                    zip: values.zip,
                    country: values.country,
                    email: values.email,
                    mainphone: values.mainphone,
                    homephone: values.homephone,
                    mobilephone: values.mobilephone,
                    workphone: values.workphone,
                    linkedin: values.linkedin,
                    preferredlocation: values.preferredlocation,
                    yearsexpertise: values.yearsexpertise,
                    relocate: values.relocate,
                    degree1: values.degree1,
                    degree2: values.degree2,
                    accounting: values.accounting,
                    administrative: values.administrative,
                    bidsproposals: values.bidsproposals,
                    contracts: values.contracts,
                    departmentvp: values.departmentvp,
                    engineeringmechanical: values.engineeringmechanical,
                    engineeringelectrical: values.engineeringelectrical,
                    engineeringindustrial: values.engineeringindustrial,
                    engineeringsoftware: values.engineeringsoftware,
                    engineeringtest: values.engineeringtest,
                    executivevp: values.executivevp,
                    facilities: values.facilities,
                    finance: values.finance,
                    humanresources: values.humanresources,
                    informationtechnology: values.informationtechnology,
                    integrationservices: values.integrationservices,
                    legal: values.legal,
                    manufacturing: values.manufacturing,
                    marketing: values.marketing,
                    partnermanagement: values.partnermanagement,
                    projectmanagement: values.projectmanagement,
                    purchasing: values.purchasing,
                    research: values.research,
                    sales: values.sales,
                    software: values.software,  
                    comments: values.comments,
                    vacancy: location.href,
                    resume: '',
                    filename: this.state.selectedFile.name,
                    mimeType: this.state.selectedFile.type,
                  })

                  fetch(`${url}?${qs}`, {
                    method: 'POST',
                    mode: 'cors',
                    body: JSON.stringify([...new Int8Array(f.target.result)]),
                  })
                    .then((res) => res.json())
                    .then((e) => {
                      this.setState({ values: '', expired: 'true' })
                      resetForm()
                      messageConfirm()
                      setSubmitting(false)
                      navigate('/careers/eeo-survey/')
                    })
                    .catch((err) => {
                      console.log(err)
                      setSubmitting(false)
                    })
                }
                //setSubmitting(false)
              }}
            >
              {({
                touched,
                errors,
                values,
                isSubmitting,
                isValid, 
                handleChange,
                handleBlur,
              }) => (
                <Form
                  name="contact"
                  method="post"
                  action=""
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                >
                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="form-name" value="contact" />

                  <section className="form-section rounded">
                  <MDBRow>
                    <MDBCol md="4">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="firstname">
                          First name <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Field
                          type="firstname"
                          name="firstname"
                          value={values.firstname}
                          placeholder="Your first name"
                          className={`form-control ${
                            touched.firstname && errors.firstname
                              ? 'is-invalid'
                              : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="firstname"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                    <MDBCol md="4">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="middlename">Middle name</label>
                        <Field
                          type="middlename"
                          name="middlename"
                          value={values.middlename}
                          placeholder="Your middle name"
                          className={`form-control ${
                            touched.middlename && errors.middlename
                              ? 'is-invalid'
                              : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="middlename"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                    <MDBCol md="4">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="surname">
                          Surname <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Field
                          type="surname"
                          name="surname"
                          value={values.surname}
                          placeholder="Your surname"
                          className={`form-control ${
                            touched.surname && errors.surname
                              ? 'is-invalid'
                              : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="surname"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="street">
                          Street <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Field
                          type="textarea"
                          component="textarea"
                          name="street"
                          value={values.street}
                          placeholder="Enter street"
                          className={`form-control ${
                            touched.street && errors.street ? 'is-invalid' : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="street"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="city">
                          City <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Field
                          type="city"
                          name="city"
                          value={values.city}
                          placeholder="Your city"
                          className={`form-control ${
                            touched.city && errors.city ? 'is-invalid' : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="city"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="state">
                          State <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Field
                          type="state"
                          name="state"
                          value={values.state}
                          placeholder="Your state"
                          className={`form-control ${
                            touched.state && errors.state ? 'is-invalid' : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="state"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="zip">
                          Zipcode <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Field
                          type="zip"
                          name="zip"
                          value={values.zip}
                          placeholder="Your zip"
                          className={`form-control ${
                            touched.zip && errors.zip ? 'is-invalid' : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="zip"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="country">
                          Country <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Field
                          type="text"
                          name="country"
                          value={values.country}
                          placeholder="Your country of residence"
                          className={`form-control ${
                            touched.country && errors.country
                              ? 'is-invalid'
                              : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="country"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                  </section>

                  <section className="form-section rounded">
                  <MDBRow>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="email">
                          Email <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Field
                          type="email"
                          name="email"
                          value={values.email}
                          placeholder="Your email address"
                          className={`form-control ${
                            touched.email && errors.email ? 'is-invalid' : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="email"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="linkedin">LinkedIn URL</label>
                        <Field
                          type="linkedin"
                          name="linkedin"
                          value={values.linkedin}
                          placeholder="Your LinkedIn URL"
                          className={`form-control ${
                            touched.linkedin && errors.linkedin
                              ? 'is-invalid'
                              : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="linkedin"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="mainphone">
                          Main phone <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Field
                          type="mainphone"
                          name="mainphone"
                          value={values.mainphone}
                          placeholder="Your main phone number"
                          className={`form-control ${
                            touched.mainphone && errors.mainphone
                              ? 'is-invalid'
                              : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="mainphone"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="mobilephone">Mobile phone</label>
                        <Field
                          type="mobilephone"
                          name="mobilephone"
                          value={values.mobilephone}
                          placeholder="Your mobile phone number"
                          className={`form-control ${
                            touched.mobilephone && errors.mobilephone
                              ? 'is-invalid'
                              : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="mobilephone"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="homephone">Home phone</label>
                        <Field
                          type="homephone"
                          name="homephone"
                          value={values.homephone}
                          placeholder="Your homephone number"
                          className={`form-control ${
                            touched.homephone && errors.homephone
                              ? 'is-invalid'
                              : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="homephone"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="workphone">Work phone</label>
                        <Field
                          type="workphone"
                          name="workphone"
                          value={values.workphone}
                          placeholder="Your work phone number"
                          className={`form-control ${
                            touched.workphone && errors.workphone
                              ? 'is-invalid'
                              : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="workphone"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                  </section>

                  <section className="form-section rounded">
                  <MDBRow>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="preferredlocation">
                          Preferred location{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Field
                          type="preferredlocation"
                          name="preferredlocation"
                          id="preferredlocation"
                          value={values.preferredlocation}
                          as="select"
                          placeholder="Please select an option"
                          className={`form-control ${
                            touched.preferredlocation &&
                            errors.preferredlocation
                              ? 'is-invalid'
                              : ''
                          }`}
                        >
                          <option value="" label="Please select a value">
                            {' '}
                          </option>
                          <option
                            value="USA - CA - Los Angeles"
                            label="USA - CA - Los Angeles"
                          >
                            {' '}
                            USA - CA - Los Angeles
                          </option>
                          <option
                            value="USA - IL - Schaumburg"
                            label="USA - IL - Schaumburg"
                          >
                            {' '}
                            USA - IL - Schaumburg
                          </option>
                          <option
                            value="USA - MA - Canton"
                            label="USA - MA - Canton"
                          >
                            {' '}
                            USA - MA - Canton
                          </option>
                          <option
                            value="USA - NH - Peterborough"
                            label="USA - NH - Peterborough"
                          >
                            {' '}
                            USA - NH - Peterborough
                          </option>
                          <option
                            value="USA - NJ - Whitehouse Station"
                            label="USA - NJ - Whitehouse Station"
                          >
                            {' '}
                            USA - NJ - Whitehouse Station
                          </option>
                          <option
                            value="USA - TX - Plano"
                            label="USA - TX - Plano"
                          >
                            {' '}
                            USA - TX - Plano
                          </option>
                          <option
                            value="USA - VA - Herndon"
                            label="USA - VA - Herndon"
                          >
                            {' '}
                            USA - VA - Herndon
                          </option>
                        </Field>
                        <ErrorMessage
                          component="div"
                          name="preferredlocation"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="mobilephone">Years of expertise</label>
                        <Field
                          type="yearsexpertise"
                          name="yearsexpertise"
                          id="yearsexpertise"
                          as="select"
                          value={values.yearsexpertise}
                          placeholder="Please select an option"
                          className={`form-control ${
                            touched.yearsexpertise && errors.yearsexpertise
                              ? 'is-invalid'
                              : ''
                          }`}
                        >
                          <option value="" label="Please select a value">
                            {' '}
                          </option>
                          <option value="None" label="None">
                            None
                          </option>
                          <option value="1-3" label="1-3">
                            1-3
                          </option>
                          <option value="3-5" label="3-5">
                            3-5
                          </option>
                          <option value="5-10" label="5-10">
                            5-10
                          </option>
                          <option value="10+" label="10+">
                            10+
                          </option>
                        </Field>
                        <ErrorMessage
                          component="div"
                          name="yearsexpertise"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="relocate">Willing to relocate?</label>
                        <Field
                          type="relocate"
                          name="relocate"
                          id="relocate"
                          as="select"
                          value={values.relocate}
                          placeholder="Please select an option"
                          className={`form-control ${
                            touched.relocate && errors.relocate
                              ? 'is-invalid'
                              : ''
                          }`}
                        >
                          <option value="" label="Please select a value">
                            {' '}
                          </option>
                          <option value="Yes" label="Yes">
                            Yes
                          </option>
                          <option value="No" label="No">
                            No
                          </option>
                        </Field>
                        <ErrorMessage
                          component="div"
                          name="relocate"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                  </section>

                  <section className="form-section rounded">
                  <MDBRow>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="degree1">
                          Degree & major #1{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Field
                          type="degree1"
                          name="degree1"
                          value={values.degree1}
                          placeholder="Degree & major #1"
                          className={`form-control ${
                            touched.degree1 && errors.degree1
                              ? 'is-invalid'
                              : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="degree1"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="degree2">Degree & major #2</label>
                        <Field
                          type="degree2"
                          name="degree2"
                          value={values.degree2}
                          placeholder="Degree & major #2"
                          className={`form-control ${
                            touched.degree2 && errors.degree2
                              ? 'is-invalid'
                              : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="degree2"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <div className="mb-1 grey-text font-w-600 text-medium">
                    <label>Areas of expertise</label>
                  </div>

                  <MDBRow>
                    <MDBCol lg="6"  className="pb-2">
                      <Field
                        type="checkbox"
                        id="accounting"
                        name="accounting"
                        className={`form-check-input ${
                          touched.accounting && errors.accounting
                            ? 'is-invalid'
                            : ''
                        }`}
                      />
                      <label htmlFor="accounting">Accounting</label>
                      <ErrorMessage
                        component="div"
                        name="accounting"
                        className="invalid-feedback"
                      />
                    </MDBCol>

                    <MDBCol lg="6"  className="pb-2">
                      <Field
                        type="checkbox"
                        id="administrative"
                        name="administrative"
                        className={`form-check-input ${
                          touched.administrative && errors.administrative
                            ? 'is-invalid'
                            : ''
                        }`}
                      />
                      <label htmlFor="administrative">Administrative</label>
                    </MDBCol>

                    <MDBCol lg="6"  className="pb-2">
                      <Field
                        type="checkbox"
                        id="bidsproposals"
                        name="bidsproposals"
                        className={`form-check-input ${
                          touched.bidsproposals && errors.bidsproposals
                            ? 'is-invalid'
                            : ''
                        }`}
                      />
                      <label htmlFor="bidsproposals">Bids & Proposals</label>
                    </MDBCol>

                    <MDBCol lg="6"  className="pb-2">
                      <Field
                        type="checkbox"
                        id="contracts"
                        name="contracts"
                        className={`form-check-input ${
                          touched.contracts && errors.contracts
                            ? 'is-invalid'
                            : ''
                        }`}
                      />
                      <label htmlFor="contracts">Contracts Management</label>
                    </MDBCol>

                    <MDBCol lg="6"  className="pb-2">
                      <Field
                        type="checkbox"
                        id="departmentvp"
                        name="departmentvp"
                        className={`form-check-input ${
                          touched.departmentvp && errors.departmentvp
                            ? 'is-invalid'
                            : ''
                        }`}
                      />
                      <label htmlFor="departmentvp">Department VP</label>
                    </MDBCol>

                    <MDBCol lg="6"  className="pb-2">
                      <Field
                        type="checkbox"
                        id="engineeringmechanical"
                        name="engineeringmechanical"
                        className={`form-check-input ${
                          touched.engineeringmechanical && errors.engineeringmechanical
                            ? 'is-invalid'
                            : ''
                        }`}
                      />
                      <label htmlFor="engineeringmechanical">Engineering - Mechanical</label>
                    </MDBCol>

                    <MDBCol lg="6"  className="pb-2">
                      <Field
                        type="checkbox"
                        id="engineeringelectrical"
                        name="engineeringelectrical"
                        className={`form-check-input ${
                          touched.engineeringelectrical && errors.engineeringelectrical
                            ? 'is-invalid'
                            : ''
                        }`}
                      />
                      <label htmlFor="engineeringelectrical">Engineering - Electrical</label>
                    </MDBCol>

                    <MDBCol lg="6"  className="pb-2">
                      <Field
                        type="checkbox"
                        id="engineeringindustrial"
                        name="engineeringindustrial"
                        className={`form-check-input ${
                          touched.engineeringindustrial && errors.engineeringindustrial
                            ? 'is-invalid'
                            : ''
                        }`}
                      />
                      <label htmlFor="engineeringindustrial">Engineering - Mfg/Industrial</label>
                    </MDBCol>

                    <MDBCol lg="6"  className="pb-2">
                      <Field
                        type="checkbox"
                        id="engineeringsoftware"
                        name="engineeringsoftware"
                        className={`form-check-input ${
                          touched.engineeringsoftware && errors.engineeringsoftware
                            ? 'is-invalid'
                            : ''
                        }`}
                      />
                      <label htmlFor="engineeringsoftware">Engineering - Software</label>
                    </MDBCol>

                    <MDBCol lg="6"  className="pb-2">
                      <Field
                        type="checkbox"
                        id="engineeringtest"
                        name="engineeringtest"
                        className={`form-check-input ${
                          touched.engineeringtest && errors.engineeringtest
                            ? 'is-invalid'
                            : ''
                        }`}
                      />
                      <label htmlFor="engineeringtest">Engineering - Test</label>
                    </MDBCol>

                    <MDBCol lg="6"  className="pb-2">
                      <Field
                        type="checkbox"
                        id="executivevp"
                        name="executivevp"
                        className={`form-check-input ${
                          touched.executivevp && errors.executivevp
                            ? 'is-invalid'
                            : ''
                        }`}
                      />
                      <label htmlFor="executivevp">Executive VP</label>
                    </MDBCol>

                    <MDBCol lg="6"  className="pb-2">
                      <Field
                        type="checkbox"
                        id="facilities"
                        name="facilities"
                        className={`form-check-input ${
                          touched.facilities && errors.facilities
                            ? 'is-invalid'
                            : ''
                        }`}
                      />
                      <label htmlFor="facilities">Facilities</label>
                    </MDBCol>

                    <MDBCol lg="6"  className="pb-2">
                      <Field
                        type="checkbox"
                        id="finance"
                        name="finance"
                        className={`form-check-input ${
                          touched.finance && errors.finance
                            ? 'is-invalid'
                            : ''
                        }`}
                      />
                      <label htmlFor="finance">Finance</label>
                    </MDBCol>

                    <MDBCol lg="6"  className="pb-2">
                      <Field
                        type="checkbox"
                        id="humanresources"
                        name="humanresources"
                        className={`form-check-input ${
                          touched.humanresources && errors.humanresources
                            ? 'is-invalid'
                            : ''
                        }`}
                      />
                      <label htmlFor="humanresources">Human Resources</label>
                    </MDBCol>

                    <MDBCol lg="6"  className="pb-2">
                      <Field
                        type="checkbox"
                        id="informationtechnology"
                        name="informationtechnology"
                        className={`form-check-input ${
                          touched.informationtechnology && errors.informationtechnology
                            ? 'is-invalid'
                            : ''
                        }`}
                      />
                      <label htmlFor="informationtechnology">Information Technology</label>
                    </MDBCol>

                    <MDBCol lg="6"  className="pb-2">
                      <Field
                        type="checkbox"
                        id="integrationservices"
                        name="integrationservices"
                        className={`form-check-input ${
                          touched.integrationservices && errors.integrationservices
                            ? 'is-invalid'
                            : ''
                        }`}
                      />
                      <label htmlFor="integrationservices">Integration Services</label>
                    </MDBCol>

                    <MDBCol lg="6"  className="pb-2">
                      <Field
                        type="checkbox"
                        id="legal"
                        name="legal"
                        className={`form-check-input ${
                          touched.legal && errors.legal
                            ? 'is-invalid'
                            : ''
                        }`}
                      />
                      <label htmlFor="legal">Legal</label>
                    </MDBCol>

                    <MDBCol lg="6"  className="pb-2">
                      <Field
                        type="checkbox"
                        id="manufacturing"
                        name="manufacturing"
                        className={`form-check-input ${
                          touched.manufacturing && errors.manufacturing
                            ? 'is-invalid'
                            : ''
                        }`}
                      />
                      <label htmlFor="manufacturing">Manufacturing</label>
                    </MDBCol>

                    <MDBCol lg="6"  className="pb-2">
                      <Field
                        type="checkbox"
                        id="marketing"
                        name="marketing"
                        className={`form-check-input ${
                          touched.marketing && errors.marketing
                            ? 'is-invalid'
                            : ''
                        }`}
                      />
                      <label htmlFor="marketing">Marketing</label>
                    </MDBCol>

                    <MDBCol lg="6"  className="pb-2">
                      <Field
                        type="checkbox"
                        id="partnermanagement"
                        name="partnermanagement"
                        className={`form-check-input ${
                          touched.partnermanagement && errors.partnermanagement
                            ? 'is-invalid'
                            : ''
                        }`}
                      />
                      <label htmlFor="partnermanagement">Partner Management</label>
                    </MDBCol>

                    <MDBCol lg="6"  className="pb-2">
                      <Field
                        type="checkbox"
                        id="projectmanagement"
                        name="projectmanagement"
                        className={`form-check-input ${
                          touched.projectmanagement && errors.projectmanagement
                            ? 'is-invalid'
                            : ''
                        }`}
                      />
                      <label htmlFor="projectmanagement">Project Management</label>
                    </MDBCol>

                    <MDBCol lg="6"  className="pb-2">
                      <Field
                        type="checkbox"
                        id="purchasing"
                        name="purchasing"
                        className={`form-check-input ${
                          touched.purchasing && errors.purchasing
                            ? 'is-invalid'
                            : ''
                        }`}
                      />
                      <label htmlFor="purchasing">Purchasing</label>
                    </MDBCol>

                    <MDBCol lg="6"  className="pb-2">
                      <Field
                        type="checkbox"
                        id="research"
                        name="research"
                        className={`form-check-input ${
                          touched.research && errors.research
                            ? 'is-invalid'
                            : ''
                        }`}
                      />
                      <label htmlFor="research">Research</label>
                    </MDBCol>

                    <MDBCol lg="6"  className="pb-2">
                      <Field
                        type="checkbox"
                        id="sales"
                        name="sales"
                        className={`form-check-input ${
                          touched.sales && errors.sales
                            ? 'is-invalid'
                            : ''
                        }`}
                      />
                      <label htmlFor="sales">Sales</label>
                    </MDBCol>

                    <MDBCol lg="6"  className="pb-2">
                      <Field
                        type="checkbox"
                        id="software"
                        name="software"
                        className={`form-check-input ${
                          touched.software && errors.software
                            ? 'is-invalid'
                            : ''
                        }`}
                      />
                      <label htmlFor="software">Software</label>
                    </MDBCol>
                  </MDBRow>
                  </section>

                  <section className="form-section rounded">
                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="comments">
                          How did you hear about UNICOM Global careers?{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Field
                          type="textarea"
                          component="textarea"
                          name="comments"
                          value={values.comments}
                          placeholder="Please let us know how you heard about UNICOM Global careers"
                          className={`form-control ${
                            touched.comments && errors.comments
                              ? 'is-invalid'
                              : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="comments"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                  </section>

                  <section className="form-section rounded">
                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 font-w-600 text-medium">
                        <label htmlFor="resume">Upload resume</label>
                        <Field
                          type="file"
                          name="resume"
                          aria-label="resume"
                          onBlur={(e) => {
                            this.setState({
                              selectedFile: e.currentTarget.files[0],
                              loaded: 0,
                            })
                          }}
                          placeholder="Upload your resume"
                          className={`form-control ${
                            touched.resume && errors.resume ? 'is-invalid' : ''
                          }`}
                        />
                        {errors.resume && (
                          <p className="is-invalid">{errors.resume}</p>
                        )}
                      </div>
                    </MDBCol>
                  </MDBRow>
                  </section>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-400 text-small">
                        For details on how your data is stored and used, please
                        visit our{' '}
                        <Link to="/privacy-statement/" className="effect">
                          Privacy Statement
                        </Link>
                        .
                      </div>
                    </MDBCol>
                  </MDBRow>
                  
                  <MDBRow>
                    <MDBCol>
                      <div style={{color: '#dc3545', fontSize: '80%', fontWeight: '600'}}>{!isValid ? '** Please resolve all form input issues above' : ''}</div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12" className="mb-4">
                      <div style={{ paddingTop: '1rem' }}>
                        <button
                          type="submit"
                          className="mt-3 nav-link btn-sm-block btn btn-mdb-color"
                          style={{ display: 'block' }}
                          disabled={isSubmitting ? true : false}
                        >
                          {isSubmitting ? 'Please wait...' : 'Submit'}
                        </button>
                        <ToastContainer
                          transition={Zoom}
                          hideProgressBar={true}
                          newestOnTop={true}
                          autoClose={5000}
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                </Form>
              )}
            </Formik>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    )
  }
}

export default formResume